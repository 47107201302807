import "./sentry.js";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/browser";

require("../resources/gtm.js");
require("../resources/intercom.js");
const lottie = require("lottie-web");

document.body.addEventListener("dragstart", (event) => {
  if (event.target && event.target.draggable) {
    // absurdly, this is needed for Firefox; see https://medium.com/elm-shorts/elm-drag-and-drop-game-630205556d2
    event.dataTransfer.setData("text/html", "blank");
  }
});
document.body.addEventListener("dragover", (event) => {
  // this is needed in order to make dragging work
  return false;
});
global.ChurnZero ||= [];
var CONSTANTS = {
  BOOT: "boot",
  CONFIG_STORAGE: "configStorage",
  CUSTOMER_SUCCESS: "customerSuccess",
  HAS_INSIGHTS: "hasInsights",
  ELM: "elm",
  FEATURES: "features",
  LOTTIE: "lottie",
  MESSAGE_KEY: "message",
  ONBOARD_TOGGLE: "onboardToggle",
  SHOW: "show",
  SHUTDOWN: "shutdown",
  STRING_TYPE: "string",
  TOKEN: "token",
};
var token = localStorage.getItem(CONSTANTS.TOKEN);
var customerSuccess = JSON.parse(
  localStorage.getItem(CONSTANTS.CUSTOMER_SUCCESS)
);
var hasInsights = JSON.parse(localStorage.getItem(CONSTANTS.HAS_INSIGHTS));
var storedDashboardConfig = localStorage.getItem(CONSTANTS.CONFIG_STORAGE);
var onboardToggle = JSON.parse(localStorage.getItem(CONSTANTS.ONBOARD_TOGGLE));
var parsedDashboardConfig = storedDashboardConfig
  ? JSON.parse(storedDashboardConfig)
  : null;
if (window.location.hash) {
  try {
    const hashParams = new URLSearchParams(
      window.location.hash.replace(/^#/, "?")
    );
    token = hashParams.get(CONSTANTS.TOKEN);
    customerSuccess = JSON.parse(hashParams.get(CONSTANTS.CUSTOMER_SUCCESS));
    hasInsights = JSON.parse(hashParams.get(CONSTANTS.HAS_INSIGHTS));
    localStorage.setItem(CONSTANTS.TOKEN, token);
    localStorage.setItem(CONSTANTS.CUSTOMER_SUCCESS, customerSuccess);
    localStorage.setItem(CONSTANTS.HAS_INSIGHTS, hasInsights);
    hashParams.delete(CONSTANTS.TOKEN);
    hashParams.delete(CONSTANTS.CUSTOMER_SUCCESS);
    hashParams.delete(CONSTANTS.HAS_INSIGHTS);
    window.location.hash = `#${hashParams.toString()}`;
  } catch (err) {
    token = null;
    customerSuccess = null;
    hasInsights = null;
    localStorage.removeItem(CONSTANTS.TOKEN);
    localStorage.removeItem(CONSTANTS.CUSTOMER_SUCCESS);
    localStorage.removeItem(CONSTANTS.HAS_INSIGHTS);
  }
}
var features = localStorage.getItem(CONSTANTS.FEATURES);
var app = require("./Main.elm").Elm.Main.init({
  node: document.getElementById(CONSTANTS.ELM),
  flags: {
    token: token,
    customerSuccess: customerSuccess,
    hasInsights: hasInsights,
    features: features,
    appSite: process.env.CONSUMER_WEB_URL,
    dashboardConfig: parsedDashboardConfig,
    thumbnailACILearning: new URL(
      "../resources/aci_default_thumbnail.jpg",
      import.meta.url
    ).pathname,
    onboardToggle: onboardToggle,
  },
});

app.ports.newTab.subscribe((url) => window.open(url, "_blank"));

app.ports.openIntercomLauncher.subscribe(function () {
  window.Intercom(CONSTANTS.SHOW);
});

app.ports.downloadCsv.subscribe(function (args) {
  var fileName = args[0];
  var csvString = args[1];
  var blob = new Blob([csvString], { type: "text/csv" });
  var url = window.URL.createObjectURL(blob);
  if (navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    var a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  window.URL.revokeObjectURL(url);
});

app.ports.setDashboardConfig.subscribe(function (dashboardConfig) {
  localStorage.setItem(
    CONSTANTS.CONFIG_STORAGE,
    JSON.stringify(dashboardConfig)
  );
});

app.ports.setOnboardToggle.subscribe(function (toggled) {
  localStorage.setItem(CONSTANTS.ONBOARD_TOGGLE, toggled);
});

app.ports.logout.subscribe(function () {
  localStorage.removeItem(CONSTANTS.TOKEN);
  localStorage.removeItem(CONSTANTS.CUSTOMER_SUCCESS);
  localStorage.removeItem(CONSTANTS.HAS_INSIGHTS);
  global.ChurnZero?.push(["stop"]);
});

app.ports.bootIntercom.subscribe(function (intercomData) {
  var data = intercomData || false;
  if (data.id) {
    window.Intercom(CONSTANTS.BOOT, {
      app_id: window.INTERCOM_APP_ID,
      user_id: data.id,
      user_hash: data.intercomHash,
      name: data.firstName + " " + data.lastName,
      email: data.username,
      company: {
        id: data.companyExternalId,
        name: data.companyName,
      },
      widget: {
        activator: "#IntercomDefaultWidget",
      },
    });
    return true;
  } else {
    window.Intercom(CONSTANTS.BOOT, {
      app_id: window.INTERCOM_APP_ID,
      widget: {
        activator: "#IntercomDefaultWidget",
      },
    });
    return true;
  }
});

app.ports.logoutIntercom.subscribe(function () {
  window.Intercom(CONSTANTS.SHUTDOWN);
  window.Intercom(CONSTANTS.BOOT, {
    app_id: window.INTERCOM_APP_ID,
    widget: {
      activator: "#IntercomDefaultWidget",
    },
  });
});

app.ports.initLottie.subscribe(function () {
  if (lottie) {
    lottie.destroy();
    setTimeout(function () {
      lottie.loadAnimation({
        container: document.getElementById(CONSTANTS.LOTTIE),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: require("../resources/Products.json"),
      });
    }, 2000);
  }
});

var portSends = {
  RECAPTCHA_LOADED: "RECAPTCHA_LOADED",
  RECAPTCHA_TOKEN: "RECAPTCHA_TOKEN",
  RECAPTCHA_EXPIRED: "RECAPTCHA_EXPIRED",
  RECAPTCHA_ERROR: "RECAPTCHA_ERROR",
};

var recaptchaSuccess = function (token) {
  var sendConfig = {};
  sendConfig[portSends.RECAPTCHA_TOKEN] = token;
  app.ports.receive.send(sendConfig);
};
var recaptchaExpired = function () {
  var sendConfig = {};
  sendConfig[portSends.RECAPTCHA_EXPIRED] = portSends.RECAPTCHA_EXPIRED;
  app.ports.receive.send(sendConfig);
};
var recaptchaError = function () {
  var sendConfig = {};
  sendConfig[portSends.RECAPTCHA_ERROR] = portSends.RECAPTCHA_ERROR;
  app.ports.receive.send(sendConfig);
};
var recaptchRenderConfig = {
  sitekey: process.env.RECAPTCHA_INVISIBLE_PUBLIC_KEY,
  badge: "bottomleft",
  callback: recaptchaSuccess,
  "expired-callback": recaptchaExpired,
  "error-callback": recaptchaError,
  size: "invisible",
};
var recaptchaBypass = process.env.RECAPTCHA_BYPASS == "1";

const recaptchaLoaded = () => {
  let sendConfig = {};
  sendConfig[portSends.RECAPTCHA_LOADED] = portSends.RECAPTCHA_LOADED;
  app.ports.receive.send(sendConfig);
};

window.recaptchaLoaded = recaptchaLoaded;

const loadRecaptcha = () => {
  const existingScript = document.getElementById("Recaptcha");

  if (!existingScript) {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=explicit`;
    script.id = "Recaptcha";
    document.body.appendChild(script);
  }
};
loadRecaptcha();

var portSubs = {
  RECAPTCHA_RENDER: "RECAPTCHA_RENDER",
  RECAPTCHA_EXECUTE: "RECAPTCHA_EXECUTE",
  RECAPTCHA_RESET: "RECAPTCHA_RESET",
  GO_TO_EXTERNAL_LINK_NEW_TAB: "GO_TO_EXTERNAL_LINK_NEW_TAB",
  SET_LOGIN_VARIABLES: "SET_LOGIN_VARIABLES"
};
app.ports.call.subscribe(function (portConfig) {
  var UNKNOWN_PORT_MESSAGE = function (location) {
    var logMessage = location + " - Elm port sent unknown message: ";
    console.log(logMessage, portConfig);
  };
  if (
    portConfig.hasOwnProperty(CONSTANTS.MESSAGE_KEY) &&
    typeof portConfig[CONSTANTS.MESSAGE_KEY] == CONSTANTS.STRING_TYPE
  ) {
    switch (portConfig[CONSTANTS.MESSAGE_KEY]) {
      case portSubs.RECAPTCHA_RENDER:
        if (!recaptchaBypass)
          window.grecaptcha.render("recaptcha", recaptchRenderConfig);
        break;
      case portSubs.RECAPTCHA_EXECUTE:
        if (recaptchaBypass) {
          recaptchaSuccess("bypass");
        } else {
          window.grecaptcha
            .execute()
            .then(recaptchaSuccess)
            .catch(recaptchaError);
        }
        break;
      case portSubs.RECAPTCHA_RESET:
        if (!recaptchaBypass) window.grecaptcha.reset();
        break;
      case portSubs.SET_LOGIN_VARIABLES:
        localStorage.setItem(CONSTANTS.TOKEN, portConfig.token);
        localStorage.setItem(CONSTANTS.CUSTOMER_SUCCESS, portConfig.customerSuccess);
        localStorage.setItem(CONSTANTS.HAS_INSIGHTS, portConfig.hasInsights);
        setChurnZero(portConfig.token);
        break;
      default:
        return UNKNOWN_PORT_MESSAGE("Switch Statement");
    }
  } else {
    return UNKNOWN_PORT_MESSAGE("If/Else");
  }
});

// Whenever localStorage changes in another tab, report it if necessary.
window.addEventListener(
  "storage",
  function (event) {
    if (
      event.storageArea === localStorage &&
      event.key === CONSTANTS.CONFIG_STORAGE
    ) {
      app.ports.configUpdate.send(JSON.parse(event.newValue));
    }
    if (
      event.storageArea === localStorage &&
      event.key === CONSTANTS.ONBOARD_TOGGLE
    ) {
      app.ports.onboardToggleUpdate.send(JSON.parse(event.newValue));
    }
  },
  false
);

app.ports.captureException.subscribe((message) => {
  console.error(message);
  Sentry.captureException(new Error(message));
});

const setChurnZero = (mToken) => {
  /* race condition; token isn't fully set yet in localStorage.
  When user first logs in pass the token from SET_LOGIN_VARIABLES Port
  all other times just get from localStorage */
  const t = mToken || token
  if (t) {
    const data = jwtDecode(t);
    // https://support.churnzero.com/hc/en-us/articles/360004683552-Integrate-ChurnZero-using-Javascript
    const cz = document.createElement("script");
    cz.type = "text/javascript";
    cz.async = true;
    cz.src = "https://itpro.us1app.churnzero.net/churnzero.js";
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(cz, s);

    global.ChurnZero.push(["setAppKey", process.env.CHURN_ZERO_APP_KEY]);
    global.ChurnZero.push([
      "setContact",
      data.companyExternalId, // company external ID (the GUID)
      data.username, // username (email address)
    ]);
  };
}
setChurnZero(null);
