module Main exposing (..)

import Api exposing (Token(..), intercomData)
import Array
import Browser
import Browser.Navigation as Navigation
import Components.Dashboard as Dashboard
import Components.Login as Login
import Components.OnboardingPanel as OBPanel
import Flags
import Helpers.Constants as Constants
import Helpers.Intercom exposing (handleIntercomResult)
import Json.Decode as Decode
import Json.Encode as Encode
import Jwt
import Model exposing (Model, initialModel)
import Model.Company as Company
import Model.LoginResult exposing (LoginResult, getTokenFromLoginResult)
import Msg exposing (Msg(..))
import Page
import Port
import Task
import Time
import Type.AppSite as AppSite
import Update exposing (update)
import Url
import View exposing (view)


begin : Encode.Value -> Url.Url -> Navigation.Key -> ( Model, Cmd Msg )
begin encodedFlags url key =
    let
        flags =
            case Decode.decodeValue Flags.decoder encodedFlags of
                Ok decodedFlags ->
                    decodedFlags

                Err _ ->
                    { token = Nothing
                    , customerSuccess = Nothing
                    , hasInsights = Nothing
                    , features = Nothing
                    , appSite = AppSite.toAppSite Constants.defaultAppSite
                    , dashboardConfig = Dashboard.defaultConfig
                    , thumbnailACILearning = "/default-aci-learning-thumbnail.jpg"
                    , onboardToggle = Nothing
                    }

        token =
            Maybe.map Token flags.token

        loginResult =
            Maybe.map3 LoginResult token flags.customerSuccess flags.hasInsights

        msg =
            Init url

        ( model, cmd ) =
            update msg <| initialModel key flags.appSite url loginResult Array.empty (Company.CompanyName "") flags.dashboardConfig flags.thumbnailACILearning
    in
    ( model
    , Cmd.batch
        [ handleIntercomResult <| Maybe.map (intercomData << getTokenFromLoginResult) model.loginResult
        , cmd
        , checkToken token
        ]
    )


checkToken : Maybe Api.Token -> Cmd Msg.Msg
checkToken maybeToken =
    case maybeToken of
        Nothing ->
            Cmd.none

        Just (Api.Token token) ->
            Task.map
                (\now ->
                    if Result.withDefault True (Jwt.isExpired now token) then
                        Msg.LogoutMsg

                    else
                        Msg.NoOp
                )
                Time.now
                |> Task.perform identity


subs : Model -> Sub Msg
subs model =
    let
        maybeOnboardingPanelSub =
            case model.onboardingPanel of
                Just _ ->
                    Sub.map Msg.OnboardingPanelMsg OBPanel.subscriptions

                Nothing ->
                    Sub.none
    in
    case model.page of
        Page.Dashboard dashboardModel ->
            Sub.batch
                [ Sub.map Msg.DashboardMsg (Dashboard.subscriptions dashboardModel)
                , maybeOnboardingPanelSub
                ]

        Page.Login _ ->
            Sub.batch
                [ Sub.map Msg.LoginMsg Login.subscriptions
                , maybeOnboardingPanelSub
                ]

        _ ->
            Sub.batch
                [ Port.configUpdate updateDashboardConfigUpdate
                , maybeOnboardingPanelSub
                ]


updateDashboardConfigUpdate : Decode.Value -> Msg.Msg
updateDashboardConfigUpdate value =
    case Decode.decodeValue Dashboard.configDecoder value of
        Ok dashboardConfig ->
            Msg.UpdateDashboardConfig dashboardConfig

        Err _ ->
            Msg.NoOp


main : Program Encode.Value Model Msg
main =
    Browser.application
        { init = begin
        , view = view
        , update = update
        , subscriptions = subs
        , onUrlRequest = Msg.OnUrlRequest
        , onUrlChange = Msg.OnUrlChange
        }
