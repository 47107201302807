const INTERCOM_APP_IDS = {
  'proportal.acilearning.com': 'g400n3to',
  'staging-proportal.acilearning.com': 'kv7isu10',
};
window.INTERCOM_APP_ID = INTERCOM_APP_IDS[window.location.host] || 'kv7isu10';

(function(){
  var w=window;
  var ic=w.Intercom;
  if(typeof ic==="function"){
    ic('reattach_activator');
    ic('update',intercomSettings);
  }else{
    var d=document;
    var i=function(){
      i.c(arguments)
    };
    i.q=[];
    i.c=function(args){
      i.q.push(args)};
      w.Intercom=i;
      function l(){
        var s=d.createElement('script');
        s.type='text/javascript';
        s.async=true;
        s.src='https://widget.intercom.io/widget/' + window.INTERCOM_APP_ID;
        var x=d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s,x);
      }
      if(w.attachEvent){
        w.attachEvent('onload',l);
      }else{
        w.addEventListener('load',l,false);
      }
    }
  }
)()
