// https://docs.sentry.io/platforms/javascript/
import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://717ad6a740df4f88b0e2dd83e05361f0@o212731.ingest.sentry.io/4505308035809280",
    integrations: [
        Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 0.01,
    environment: {
        'proportal.acilearning.com': 'production',
        'staging-proportal.acilearning.com': 'staging',
    }[location.host] || 'development',
});
